const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VALID_FILES_EXT = ["aBO", "aBR", "sBO", "sBR", "xls", "txt", "xlsx"];

const VALID_FILES_REGEX: RegExp[] = [
  /^[0-9]{6}rg.a(?:BO|BR)$/,
  /^[0-9]{6}re.s(?:BO|BR)$/,
  /^[0-9]{6}.xls$/,
  /^ACDS_UCESA_[0-9]{6}.xlsx$/,
  /^DR_MR_[0-9]{6}.xlsx$/,
  /^SINAPS_UCEPA_[0-9]{4}.txt$/,
  /^SINAPS_UCESA_[0-9]{6}.txt$/,
];

type TagData = { dbField: string; name: string };

const TAG_DATA: TagData[] = [
  { dbField: "strike_am", name: "STRIKE AM" },
  { dbField: "strike_pm", name: "STRIKE PM" },
  { dbField: "technical_am", name: "TECH. PB AM" },
  { dbField: "technical_pm", name: "TECH. PB PM" },
  { dbField: "weather_am", name: "WEATHER AM" },
  { dbField: "weather_pm", name: "WEATHER PM" },
  { dbField: "other_am", name: "OTHER AM" },
  { dbField: "other_pm", name: "OTHER PM" },
];

export { API_BASE_URL, VALID_FILES_REGEX, VALID_FILES_EXT, TAG_DATA };
