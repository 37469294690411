import axios from "axios";
import { complement, filter, isNil } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { logApiError } from "./logApiError";
import { useInterval } from "./useInterval";

const isNotNil = complement(isNil);

export type Params = {
  [key: string]: string | null | undefined;
};

export type Credentials = {
  username?: string;
  password?: string;
};

export function useFetch<T>({
  initialBaseURL,
  initialPath,
  initialParams = {},
  credentials = {},
  skip = false,
  poll = null,
}: {
  initialBaseURL: string;
  initialPath: string;
  initialParams?: Params;
  credentials?: Credentials;
  skip?: boolean;
  poll?: null | number;
}) {
  const [baseURL, updateBaseURL] = useState(initialBaseURL);
  const [path, updatePath] = useState(initialPath);
  const [params, updateParams] = useState<Params>(initialParams);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [refetchIndex, setRefetchIndex] = useState(0);

  const { username, password } = credentials;

  const refetch = useCallback(
    () => setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1),
    []
  );

  useEffect(() => {
    let isMounted = true;
    const currentRequest = axios.CancelToken.source();
    const fetchData = async () => {
      if (skip) return;
      try {
        console.log("Fetching new data with URL : ", baseURL + path);
        setIsLoading(true);
        const response = await axios.get(path, {
          cancelToken: currentRequest.token,
          baseURL,
          params: filter(isNotNil, params),
          ...(password && username && { auth: { username, password } }),
        });
        if (!isMounted) {
          return;
        }
        if (!response)
          throw new Error(`failed : no response from url ${baseURL + path}`);
        setData(response.data);
        error && setError(null);
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        if (!isMounted) {
          return;
        }
        logApiError(error);
        if (axios.isAxiosError(error)) {
          console.log(`request config : ${error.config}}`);
        }
        setError(`no reply from api at ${baseURL + path}`);
      } finally {
        if (!isMounted) {
          return;
        }
        setIsLoading(false);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
      currentRequest.cancel();
    };
  }, [params, refetchIndex, skip, username, password, baseURL, path, error]);

  useInterval(refetch, poll);

  return {
    data,
    error,
    isLoading,
    updateBaseURL,
    updateParams,
    refetch,
    refetchIndex,
  };
}
export default useFetch;
