import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import UploadIcon from "@mui/icons-material/Upload";
import {
  AppBar,
  IconButton,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { difference } from "ramda";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { API_BASE_URL, VALID_FILES_EXT } from "../Constant";
import { isValidFile } from "../utils/isValidFile";
import { sendFile } from "../utils/sendFile";
import { InfoBox } from "./InfoBox";

const API_ACTION_URL = new URL("/action", API_BASE_URL);

type UploadStatus = {
  success: string[];
  failed: string[];
  loading: Boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    margin: {
      margin: theme.spacing(1),
    },
    dropZone: {
      display: "flex",
      flexDirection: "column",

      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[800],
      borderRadius: "10px",
      padding: "20px",
    },
    paper: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      alignItem: "center",
      height: 770,
    },
    subTitle: {
      backgroundColor: "#222c38",
      "& Typography": { flexGrow: 1 },
    },
  })
);
// toDo improve display in the itemData Box not centered buttons and valid file statement

export function SendingDataBox() {
  const classes = useStyles();

  const [fileList, setFileList] = useState<File[]>([]);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({
    success: [],
    failed: [],
    loading: false,
  });

  const onDrop = useCallback((droppedFiles: File[]) => {
    const acceptedFiles = Array.from(droppedFiles).filter(isValidFile);
    if (acceptedFiles.length !== droppedFiles.length) {
      toast.error(
        `One or more files not accepted - valid files : ${VALID_FILES_EXT}`
      );
      const rejectedFiles = difference(droppedFiles, acceptedFiles);
      rejectedFiles.forEach((file) => toast.error(file.name));
    }
    setFileList((fileList) => [...fileList, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const onClick = useCallback(async () => {
    if (fileList.length === 0) return null;
    setUploadStatus({ success: [], failed: [], loading: true });

    const uploadResult = await Promise.all(
      fileList.map((item) =>
        sendFile(item).then((result) => {
          console.log("statut de l'envoi :", result);
          if (result.status === "success") {
            setUploadStatus((status) => ({
              ...status,
              success: [...status.success, result.name],
            }));
          }
          if (result.status === "failed") {
            setUploadStatus((status) => ({
              ...status,
              failed: [...status.failed, result.name],
            }));
          }
          return result;
        })
      )
    );

    if (uploadResult.every((item) => item.status === "success")) {
      toast.success("Files uploaded successfully");
    } else {
      toast.error("Failed to load one or more files");
    }

    setUploadStatus((status) => ({ ...status, loading: false }));
    setFileList([]);
  }, [fileList]);

  const onClear = useCallback(() => {
    setFileList([]);
  }, []);

  async function retryProcessFile() {
    await axios.post(
      API_ACTION_URL.toString(),
      {},
      { params: { action: "RETRY" } }
    );
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.subTitle}>
          <Typography variant="h6">SEND DATA</Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.paper}>
        <Box {...getRootProps({ className: classes.dropZone })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <UploadIcon fontSize="large" />
          <Typography className={classes.margin}>
            {fileList?.length ?? 0} valid file(s) selected
          </Typography>
        </Box>
        <IconButton
          onClick={onClear}
          disabled={fileList.length === 0}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
        <Button
          variant="contained"
          className={classes.margin}
          color="primary"
          disabled={fileList.length === 0 || uploadStatus.loading === true}
          endIcon={<SendIcon />}
          onClick={onClick}
        >
          Envoyer
        </Button>
        <InfoBox
          retryProcessFile={retryProcessFile}
          failedList={uploadStatus.failed}
          successList={uploadStatus.success}
        />
      </Paper>
    </>
  );
}
