import { CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useKeycloak } from "@react-keycloak/web";
import { isNull } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { FatalBox } from "./Components/FatalBox";
import { MissingDataBox } from "./Components/MissingDataBox";
import { SendingDataBox } from "./Components/SendingDataBox";
import { SinapsDataBox } from "./Components/SinapsDataBox";
import { TagDataBox } from "./Components/TagDataBox";
import { TitleBox } from "./Components/TitleBox";
import { API_BASE_URL } from "./Constant";
import useFetch from "./utils/useFetch";

function App() {
  const { keycloak, initialized } = useKeycloak();
  const [authorized, setAuthorized] = useState<boolean | null>(null);
  const [centerId, setCenterId] = useState<string | null>(null);

  const login = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  const logout = useCallback(() => {
    keycloak?.logout();
  }, [keycloak]);

  const { data, refetch } = useFetch<{
    centerId: string;
    auth: boolean;
  }>({
    initialBaseURL: API_BASE_URL!,
    initialPath: "/id",
    skip: !initialized || !keycloak.authenticated,
  });

  useEffect(() => {
    if (initialized && !keycloak.authenticated) login();
    if (initialized && keycloak.authenticated) refetch();
  }, [initialized, keycloak, login, refetch]);

  useEffect(() => {
    setAuthorized(data?.auth ?? null);
    setCenterId(data?.centerId ?? null);
  }, [data]);

  if (!initialized || !keycloak.authenticated || isNull(authorized)) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!authorized) {
    return (
      <Box sx={{ p: "1vmax", minWidth: 400 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TitleBox logout={logout} centerId={centerId} />
          </Grid>
          <Grid item xs={12} md={6} xl={3} sx={{ height: "100%" }}>
            <FatalBox />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ p: "1vmax", minWidth: 400 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TitleBox logout={logout} centerId={centerId} />
        </Grid>
        <Grid item xs={12} md={6} xl={3} sx={{ height: "100%" }}>
          <SendingDataBox />
        </Grid>
        <Grid item xs={12} md={6} xl={3} sx={{ height: "100%" }}>
          <MissingDataBox />
        </Grid>
        <Grid item xs={12} md={6} xl={3} sx={{ height: "100%" }}>
          <TagDataBox />
        </Grid>
        <Grid item xs={12} md={6} xl={3} sx={{ height: "100%" }}>
          <SinapsDataBox />
        </Grid>
      </Grid>
      <Toaster position="bottom-center" />
    </Box>
  );
}

export default App;
