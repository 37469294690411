import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import PickersDay from "@mui/lab/PickersDay";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import {
  AppBar,
  Badge,
  CircularProgress,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import { isNull } from "lodash";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../Constant";
import useFetch from "../utils/useFetch";
import TagSelectBox from "./TagAssignTool";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      alignItem: "center",
      height: 770,
    },
    subTitle: {
      backgroundColor: "#222c38",
      "& Typography": { flexGrow: 1 },
    },
  })
);

const initialDate = new Date();

export function TagDataBox() {
  const classes = useStyles();

  const [day, setDay] = useState<Date | null>(initialDate);
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);

  const {
    data: tagsReceived,
    isLoading,
    updateParams: updateSingleDate,
  } = useFetch<string[]>({
    initialBaseURL: API_BASE_URL!,
    initialPath: "/tags/day",
    skip: false,
    initialParams: { day: format(initialDate, "yyyy-MM-dd") },
  });

  const {
    data: tagsHiglighted,
    refetch,
    updateParams: updateWholeMonth,
  } = useFetch<number[] | null>({
    initialBaseURL: API_BASE_URL!,
    initialPath: "/tags/month",
    skip: false,
    initialParams: { day: format(initialDate, "yyyy-MM-dd") },
  });

  const handleChange = (day: Date | null) => {
    if (isNull(day)) return;
    setDay(day);
    updateSingleDate({ day: format(day, "yyyy-MM-dd") });
  };

  const handleCompleteChange = (day: Date) => {
    setHighlightedDays([]);
    setDay(day);
    updateSingleDate({ day: format(day, "yyyy-MM-dd") });
    updateWholeMonth({ day: format(day, "yyyy-MM-dd") });
  };

  useEffect(() => {
    if (!isNull(tagsHiglighted)) setHighlightedDays(tagsHiglighted);
  }, [tagsHiglighted]);

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.subTitle}>
          <Typography variant="h6">TAG SPECIFIC DAY</Typography>
          {isLoading && <CircularProgress />}
        </Toolbar>
      </AppBar>
      <Paper className={classes.paper}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            value={day}
            onChange={handleChange}
            minDate={new Date("2018-01-01")}
            onMonthChange={handleCompleteChange}
            onYearChange={handleCompleteChange}
            renderInput={(params) => <TextField {...params} />}
            renderDay={(day, _value, DayComponentProps) => {
              const isSelected =
                !DayComponentProps.outsideCurrentMonth &&
                highlightedDays.indexOf(day.getDate()) !== -1;

              return (
                <Badge
                  key={format(day, "yyyy-MM-dd")}
                  overlap="circular"
                  badgeContent={isSelected ? "🌚" : undefined}
                >
                  <PickersDay {...DayComponentProps} />
                </Badge>
              );
            }}
          />
        </LocalizationProvider>

        <TagSelectBox
          date={isNull(day) ? "" : format(day, "yyyy-MM-dd")}
          tagsReceived={tagsReceived}
          refetch={refetch}
        />
      </Paper>
    </>
  );
}
