import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { deepPurple, pink } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { uniq } from "lodash";
import { Fragment, useState } from "react";

type RulesEntry = {
  type: string;
  rule: string;
  periodicity: string;
  convention: string;
  sample: string;
};

const commonRules: RulesEntry[] = [
  {
    type: "xls",
    rule: "Naming rules for xls LEA files : YYMMDD.xls",
    periodicity: "daily",
    convention: "YY = year, MM = month , DD = day",
    sample: "'210112.xls' being the LEA file of 12 january 2021",
  },
  {
    type: "xlsx",
    rule: "Naming rules for xlsx ACDS_UCESA files : ACDS_UCESA_YYMMDD.xlsx",
    periodicity: "daily",
    convention: "YY = year, MM = month , DD = day",
    sample:
      "'ACDS_UCESA_210203.xlsx' being the ACDS_UCESA file of 03 february 2021",
  },
  {
    type: "xlsx",
    rule: "Naming rules for xlsx DR files : DR_MR_YYMMDD.xlsx",
    periodicity: "none",
    convention: "YY = year, MM = month , DD = day",
    sample: "'DR_MR_220310.xlsx' being the DR file of 10 march 2022",
  },
  {
    type: "txt",
    rule: "Naming rules for txt SINAPS_UCESA files : SINAPS_UCESA_YYMMDD.txt",
    periodicity: "weekly (every monday)",
    convention: "YY = year, MM = month , DD = day",
    sample:
      "'SINAPS_UCESA_210118.txt' being the SINAPS_UCESA file of Monday 18 january 2021",
  },
  {
    type: "txt",
    rule: "Naming rules for txt SINAPS_UCEPA files : SINAPS_UCEPA_YYMM.txt",
    periodicity: "every 28 days (following AIRAC calendar)",
    convention: "YY = year , MM = month",
    sample:
      "'SINAPS_UCEPA_2107.txt' being the SINAPS_UCEPA file of Thursday 15 july 2021",
  },
];

const lfbbRules: RulesEntry[] = [
  {
    type: "aBO",
    rule: "Naming rules : YYMMDDrg.aBO",
    periodicity: "daily",
    convention: "YY = year, MM = month , DD = day",
    sample: "'201201rg.aBO' being the aBO file of 01 december 2020",
  },
  {
    type: "sBO",
    rule: "Naming rules : YYMMDDre.sBO",
    periodicity: "daily",
    convention: "YY = year, MM = month , DD = day",
    sample: `'191112re.sBO' being the sBO file of 12 november 2019 `,
  },
  ...commonRules,
];

const lfrrRules: RulesEntry[] = [
  {
    type: "aBR",
    rule: "Naming rules : YYMMDDrg.aBR",
    periodicity: "daily",
    convention: "YY = year, MM = month , DD = day",
    sample: "'201201rg.aBR' being the aBR file of 01 december 2020",
  },
  {
    type: "sBR",
    rule: "Naming rules : YYMMDDre.sBR",
    periodicity: "daily",
    convention: "YY = year, MM = month , DD = day",
    sample: `'191112re.sBR' being the sBR file of 12 november 2019 `,
  },
  ...commonRules,
];

function isEven(n: number) {
  return n % 2 === 0;
}

function render(entry: RulesEntry, index: number) {
  return (
    <Fragment key={index}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: isEven(index) ? deepPurple[500] : pink[200],
              fontSize: 14,
            }}
          >
            {entry.type.toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={entry.rule}
          secondary={
            <Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Periodicity: {entry.periodicity}
              </Typography>
              <br />
              Convention: {entry.convention}
              <br />
              <Typography
                sx={{
                  display: "block",
                  marginTop: 1,
                  fontStyle: "italic",
                }}
                component="span"
                variant="body2"
                color="#6a7c9d"
              >
                {entry.sample}
              </Typography>
            </Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );
}

export function HelpBox({ centerId }: { centerId: string | null }) {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const data = centerId === "lfrr" ? lfrrRules : lfbbRules;
  const allowedExtension = uniq(data.map((i) => i.type));

  return (
    <>
      <Button
        sx={{ m: 1 }}
        variant="contained"
        endIcon={<HelpOutlineIcon />}
        onClick={openDialog}
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>HELP</Box>
      </Button>
      <Dialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Description of Compliant Files</Box>
            <Box>
              <IconButton onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            List of accepted file extensions: `{allowedExtension.join(", ")}`
          </Typography>
          <br />
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {data.map((item, index) => render(item, index))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
