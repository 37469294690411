import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from "@mui/material";
import axios from "axios";

import { equals } from "ramda";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API_BASE_URL, TAG_DATA } from "../Constant";

type tagProps = {
  date: string;
  tagsReceived: string[] | null;
  refetch: () => void;
};

const API_ACTION_URL = new URL("/tags", API_BASE_URL);

export default function TagSelectBox({
  date,
  tagsReceived,
  refetch,
}: tagProps) {
  const [currentTags, setCurrentTags] = useState<string[] | null>([]);
  const [selection, setSelection] = useState<
    { name: string; checked: boolean }[]
  >([]);

  useEffect(() => {
    setSelection(
      TAG_DATA.map((item) => ({
        name: item.name,
        checked: tagsReceived?.includes(item.name) ?? false,
      }))
    );
    setCurrentTags(tagsReceived);
  }, [tagsReceived]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSelection(
        selection.map((item) =>
          item.name === event.target.name
            ? { name: event.target.name, checked }
            : item
        )
      );
    },
    [selection, setSelection]
  );

  const handleSave = async () => {
    const tags = selection
      .filter((item) => item.checked)
      .map((item) => item.name);

    try {
      const result = await axios.post(API_ACTION_URL.toString(), {
        date,
        tags,
      });
      const tagsStored = result.data.tags;
      setCurrentTags(tagsStored);
      refetch();
    } catch (error) {
      toast.error("tags selection saving failed !");
    }
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          m: 2,
          paddingLeft: "10%",
          border: "1px solid #393939",
          height: 180,
          overflow: "auto",
        }}
      >
        <FormControl>
          <Grid container spacing={4}>
            {TAG_DATA.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={item.name}
                sx={{ height: 35, minWidth: 195 }}
              >
                <FormControlLabel
                  label={item.name}
                  control={
                    <Checkbox
                      name={item.name}
                      checked={
                        selection.some(
                          (i) => item.name === i.name && i.checked
                        ) ?? false
                      }
                      onChange={handleChange}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </FormControl>
      </Box>
      <Button
        sx={{ m: 2 }}
        variant="contained"
        endIcon={<HelpOutlineIcon />}
        onClick={handleSave}
        disabled={equals(
          selection.filter((item) => item.checked).map((item) => item.name),
          currentTags
        )}
      >
        SAVE
      </Button>
    </>
  );
}
