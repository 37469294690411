import {
  AppBar,
  CircularProgress,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { isEmpty, isNull } from "lodash";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../Constant";
import useFetch from "../utils/useFetch";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  Export,
} from "devextreme-react/chart";
import { CronJob } from "cron";

type ForecastData = {
  forecast: number;
  forecast_time: string;
  ucesa: number;
  config: string;
  request_time: string;
};

type Target = { target: string };
type Ucesa = { [key: string]: number };
type sinapsDisplay = Target | Ucesa;

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: 16,
      paddingTop: 40,
      display: "flex",
      flexDirection: "column",
      alignItem: "center",
      height: 770,
    },
    subTitle: {
      backgroundColor: "#222c38",
      "& Typography": { flexGrow: 1 },
    },
  })
);

export function SinapsDataBox() {
  const classes = useStyles();

  const [finalData, setFinalData] = useState<sinapsDisplay[]>();
  const { data, isLoading, refetch } = useFetch<ForecastData[]>({
    initialBaseURL: API_BASE_URL!,
    initialPath: "/sinaps",
    skip: false,
  });
  // console.log("received data ", data);

  useEffect(() => {
    var job = new CronJob("2,17,32,47 * * * *", refetch);
    job.start();
    return () => {
      job.stop();
    };
  }, [refetch]);

  useEffect(() => {
    if (isNull(data)) return;
    // data expected by DevExtreme `CHART` is an array of objects like
    // this one :{ target: "13:45", ucesa_90: 6, ucesa_120: 6, ucesa_150: 6, ucesa_180: 7 }

    // get all unique occurence of `forecast_time` sorted by time
    const timeList = data
      .map((item) => item.forecast_time)
      .filter((item, index, ar) => ar.indexOf(item) === index)
      .sort();

    // group data by`forecast_time`
    const subData = timeList.map((val) =>
      data.filter((item) => item.forecast_time === val)
    );

    // build final data
    const result: sinapsDisplay[] = subData.map((arr) =>
      arr.reduce((obj, item) => {
        if (isEmpty(obj)) {
          return {
            ...obj,
            target: item.forecast_time.substring(11, 16),
            ["ucesa_" + item.forecast]: item.ucesa,
          };
        } else {
          return { ...obj, ["ucesa_" + item.forecast]: item.ucesa };
        }
      }, {})
    );
    // console.log("subData ", subData);
    // console.log("timeList", timeList);
    // console.log("finalData ", result);

    setFinalData(result);
  }, [data]);

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.subTitle}>
          <Typography variant="h6">SINAPS REAL-TIME FORECASTS</Typography>
          {isLoading && <CircularProgress />}
        </Toolbar>
      </AppBar>
      <Paper className={classes.paper}>
        {finalData && (
          <Chart id="chart" dataSource={finalData}>
            <CommonSeriesSettings
              argumentField="target"
              type="bar"
              hoverMode="allArgumentPoints"
              selectionMode="allArgumentPoints"
            ></CommonSeriesSettings>
            <Series
              argumentField="target"
              valueField="ucesa_90"
              name="ucesa 90mn"
            />
            <Series valueField="ucesa_120" name="ucesa 120mn" />
            <Series valueField="ucesa_150" name="ucesa 150mn" />
            <Series valueField="ucesa_180" name="ucesa 180mn" />
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
            ></Legend>
            <Export enabled={true} />
          </Chart>
        )}
      </Paper>
    </>
  );
}
