import RetryIcon from "@mui/icons-material/Replay";
import {
  AppBar,
  CircularProgress,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { countBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { API_BASE_URL } from "../Constant";
import { useFetch } from "../utils/useFetch";
import { FilterTypeBox, FilterYearBox } from "./MissingFilterTools";

function isEven(n: number) {
  return n % 2 === 0;
}
interface MissingEntry {
  dataType: string;
  date: string;
}

type MissingList = MissingEntry[];

export type FilterTypeEntry = { type: string; count: number };

export type FilterYearEntry = { year: string; count: number };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    oddItem: {
      backgroundColor: theme.palette.grey[700],
    },
    evenItem: {
      backgroundColor: theme.palette.grey[800],
    },
    firstColumn: { flexGrow: 0, flexBasis: "15%" },
    secondColumn: { flexGrow: 0, flexBasis: "40%", textAlign: "center" },
    paper: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      alignItem: "center",
      height: 770,
    },
    subTitle: {
      backgroundColor: "#222c38",
      justifyContent: "space-between",
      "& Typography": { flexGrow: 1 },
    },
  })
);

export function MissingDataBox() {
  const classes = useStyles();

  const [typeFilters, setTypeFilters] = useState<string[]>([]);
  const [yearFilters, setYearFilters] = useState<string[]>([]);

  const {
    data: missingData,
    isLoading,
    refetch,
  } = useFetch<MissingList>({
    initialBaseURL: API_BASE_URL!,
    initialPath: "/missing",
    skip: false,
  });

  const typeOptions: FilterTypeEntry[] = useMemo(
    () =>
      Object.entries(
        countBy((missingData ?? []).map((item) => item.dataType))
      ).map(([type, count]) => ({
        type,
        count,
      })),
    [missingData]
  );

  const yearOptions: FilterYearEntry[] = useMemo(
    () =>
      Object.entries(
        countBy((missingData ?? []).map((item) => item.date.slice(0, 4)))
      ).map(([year, count]) => ({
        year,
        count,
      })),
    [missingData]
  );

  const filteredData = (missingData ?? [])
    .filter((item) => typeFilters.includes(item.dataType))
    .filter((item) => yearFilters.includes(item.date.slice(0, 4)));

  const rowRenderer = useCallback(
    ({ index, data, style }: ListChildComponentProps) => {
      if (!data) return null;
      return (
        <ListItem
          style={style}
          key={`${data[index].type}${data[index].dataType}`}
          className={isEven(index) ? classes.evenItem : classes.oddItem}
        >
          <ListItemText
            primary={`${index + 1}`}
            className={classes.firstColumn}
          />
          <ListItemText
            className={classes.secondColumn}
            primary={`${data[index].dataType}`}
          />
          <ListItemText primary={`${data[index].date}`} />
        </ListItem>
      );
    },
    [
      classes.evenItem,
      classes.firstColumn,
      classes.oddItem,
      classes.secondColumn,
    ]
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.subTitle}>
          <Typography variant="h6">DATABASE STATUS</Typography>
          {!isLoading && (
            <Button
              variant="contained"
              title="Check missing data dates"
              onClick={refetch}
              endIcon={<RetryIcon />}
            >
              REFRESH
            </Button>
          )}
          {isLoading && <CircularProgress />}
        </Toolbar>
      </AppBar>
      <Paper className={classes.paper}>
        <Box sx={{ height: "100%", minHeight: 700 }}>
          {filteredData && (
            <>
              <FilterTypeBox
                typeFilters={typeFilters}
                setTypeFilters={setTypeFilters}
                typeOptions={typeOptions}
              />
              <FilterYearBox
                yearFilters={yearFilters}
                setYearFilters={setYearFilters}
                yearOptions={yearOptions}
              />
              <Typography variant="h5" sx={{ paddingTop: 1 }}>
                {typeFilters.length > 0 && yearFilters.length > 0
                  ? `Missing Data (total = ${filteredData.length})`
                  : typeOptions.length > 0 && yearOptions.length > 0
                  ? `Select one Type and one Year (at least)`
                  : `No missing Data in the database`}
              </Typography>
              <AutoSizer>
                {({ height, width }) => (
                  <Box sx={{ m: 1 }}>
                    <FixedSizeList
                      width={width - 15}
                      height={height - 120}
                      itemCount={filteredData.length}
                      itemData={filteredData}
                      itemSize={42}
                    >
                      {rowRenderer}
                    </FixedSizeList>
                  </Box>
                )}
              </AutoSizer>
            </>
          )}
        </Box>
      </Paper>
    </>
  );
}
