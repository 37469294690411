import Keycloak from "keycloak-js";

const KEYCLOAK_ENDPOINT = process.env.REACT_APP_KEYCLOAK_ENDPOINT ?? "";

const keycloak = Keycloak({
  realm: "lfbb-atc",
  url: KEYCLOAK_ENDPOINT,
  clientId: "fmp-postops",
});

export default keycloak;
