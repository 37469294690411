import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //   main: blue[400],
    // },
    // secondary: {
    //   main: cyan[400],
    // },
  },
});

export const darkTheme = responsiveFontSizes(theme, {
  breakpoints: ["xs", "sm", "md", "lg"],
  factor: 20,
});
