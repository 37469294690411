import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect } from "react";

type Props = {
  refreshTime: Date | null;
};

export const TokenUpdater: React.FC<Props> = ({ refreshTime }) => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = initialized
      ? `Bearer ${keycloak.token}`
      : "";
  }, [keycloak.token, initialized]);

  return null;
};
