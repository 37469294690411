import RetryIcon from "@mui/icons-material/Replay";
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../Constant";
import { taskManager } from "../utils/taskManager";
import { useFetch } from "../utils/useFetch";
import { useInterval } from "../utils/useInterval";

type Props = {
  retryProcessFile: () => Promise<void>;
  failedList: string[];
  successList: string[];
};

type InfoQueryResponse = {
  processingFiles: number;
  failedFiles: number;
};

const useStyles = makeStyles({
  linear: {
    width: "30vw",
  },
});

export const InfoBox: React.FC<Props> = ({
  retryProcessFile,
  failedList,
  successList,
}) => {
  const classes = useStyles();

  const [stats, setStats] = useState<{
    remainingFiles: number;
    maximumRemainingFiles: number;
    processingFiles: number;
    maximumProcessingFiles: number;
    failedFiles: number;
  }>({
    remainingFiles: 0,
    maximumRemainingFiles: 0,
    processingFiles: 0,
    maximumProcessingFiles: 0,
    failedFiles: 0,
  });

  const { data, error } = useFetch<InfoQueryResponse>({
    initialBaseURL: API_BASE_URL!,
    initialPath: "/info",
    poll: 2000,
  });

  useEffect(() => {
    if (!data) return;
    if (
      data.processingFiles !== stats.processingFiles ||
      data.failedFiles !== stats.failedFiles
    ) {
      setStats((infos) => ({
        ...infos,
        ...data,
        maximumProcessingFiles:
          data.processingFiles > 0
            ? Math.max(data.processingFiles, infos.maximumProcessingFiles)
            : 0,
      }));
    }
  }, [data, stats.failedFiles, stats.processingFiles]);

  useInterval(async () => {
    async function refreshTasksCounts() {
      const { RECEIVED, QUEUED, RUNNING, EXECUTING } = taskManager.counts();
      const remainingFiles = RECEIVED + QUEUED + RUNNING + EXECUTING;
      remainingFiles !== stats.remainingFiles &&
        setStats((infos) => ({
          ...infos,
          remainingFiles,
          maximumRemainingFiles:
            remainingFiles > 0
              ? Math.max(remainingFiles, infos.maximumRemainingFiles)
              : 0,
        }));
    }
    refreshTasksCounts();
  }, 200);

  const progress1 =
    stats.maximumRemainingFiles !== 0
      ? Math.round((stats.remainingFiles / stats.maximumRemainingFiles) * 100)
      : 0;

  const progress2 =
    stats.maximumProcessingFiles !== 0
      ? Math.round(
          100 - (stats.processingFiles / stats.maximumProcessingFiles) * 100
        )
      : 100;

  const progress3 =
    stats.maximumRemainingFiles !== 0
      ? Math.round((successList.length / stats.maximumRemainingFiles) * 100)
      : 100;

  const progress4 =
    stats.maximumRemainingFiles !== 0
      ? Math.round((failedList.length / stats.maximumRemainingFiles) * 100)
      : 100;

  return (
    <Box sx={{ display: "contain" }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ACTION</TableCell>
              <TableCell align="right">QUANTITY</TableCell>
              <TableCell align="left" className={classes.linear}>
                PROGRESS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="transfer">
              <TableCell component="th" scope="row">
                UPLOADING
              </TableCell>
              <TableCell align="right">{stats.remainingFiles}</TableCell>
              <TableCell align="right">
                <LinearProgress variant="determinate" value={progress1} />
              </TableCell>
            </TableRow>
            <TableRow key="sucess">
              <TableCell component="th" scope="row">
                UPLOAD SUCCESS
              </TableCell>
              <TableCell align="right">
                <Typography color={(theme) => theme.palette.success.main}>
                  {successList.length}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <LinearProgress
                  color="success"
                  variant="determinate"
                  value={progress3}
                />
              </TableCell>
            </TableRow>
            <TableRow key="failed">
              <TableCell component="th" scope="row">
                UPLOAD FAILED
              </TableCell>
              <TableCell align="right">
                {failedList.length ? (
                  <Typography color="error">{failedList.length}</Typography>
                ) : null}
              </TableCell>
              <TableCell align="right">
                {failedList.length ? (
                  <LinearProgress
                    color="error"
                    variant="determinate"
                    value={progress4}
                  />
                ) : (
                  "--"
                )}
              </TableCell>
            </TableRow>
            <TableRow key="process">
              <TableCell component="th" scope="row">
                WORK IN PROGRESS
              </TableCell>
              <TableCell align="right">
                {error ? "?" : stats.processingFiles}
              </TableCell>
              <TableCell align="right">
                {error ? (
                  <Typography color="error">disconnected from api</Typography>
                ) : (
                  <LinearProgress variant="determinate" value={progress2} />
                )}
              </TableCell>
            </TableRow>
            <TableRow key="process-failed">
              <TableCell component="th" scope="row">
                <Box component="span" marginRight="10px">
                  PROCESS FAILED
                </Box>
                <IconButton
                  onClick={retryProcessFile}
                  title="Retry to process files to database"
                >
                  <RetryIcon />
                </IconButton>
              </TableCell>
              <TableCell align="right">
                {error ? (
                  "?"
                ) : stats.failedFiles > 0 ? (
                  <Typography color="error">{stats.failedFiles}</Typography>
                ) : null}
              </TableCell>
              <TableCell align="right">
                {error ? (
                  <Typography color="error">disconnected from api</Typography>
                ) : (
                  "---"
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
