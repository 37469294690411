import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useCallback, useEffect } from "react";
import { FilterTypeEntry, FilterYearEntry } from "./MissingDataBox";

type FilterTypeProps = {
  typeFilters: string[];
  setTypeFilters: React.Dispatch<React.SetStateAction<string[]>>;
  typeOptions: FilterTypeEntry[];
};

export function FilterTypeBox({
  typeFilters,
  setTypeFilters,
  typeOptions,
}: FilterTypeProps) {
  useEffect(() => {
    setTypeFilters(typeOptions.map((item) => item.type));
  }, [setTypeFilters, typeOptions]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      setTypeFilters(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    },
    [setTypeFilters]
  );

  return (
    <FormControl sx={{ m: 1, width: "45%" }}>
      <InputLabel id="type-filter-label">Type</InputLabel>
      <Select
        labelId="type-filter-label"
        id="type-filter"
        multiple
        value={typeFilters}
        onChange={handleChange}
        input={<OutlinedInput label="Type " />}
        renderValue={(selected) => selected.join(", ")}
      >
        {typeOptions.map((item) => (
          <MenuItem key={item.type} value={item.type}>
            <Checkbox checked={typeFilters.indexOf(item.type) > -1} />
            <ListItemText primary={`${item.type} (${item.count})`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

type FilterYearProps = {
  yearFilters: string[];
  setYearFilters: React.Dispatch<React.SetStateAction<string[]>>;
  yearOptions: FilterYearEntry[];
};

export function FilterYearBox({
  yearFilters,
  setYearFilters,
  yearOptions,
}: FilterYearProps) {
  useEffect(() => {
    const years = yearOptions.map((item) => parseInt(item.year));
    const maxYear = [years.length ? Math.max(...years).toString() : ""];
    setYearFilters(maxYear);
  }, [setYearFilters, yearOptions]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      setYearFilters(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    },
    [setYearFilters]
  );

  return (
    <FormControl sx={{ m: 1, width: "45%" }}>
      <InputLabel id="year-filter-label">Year</InputLabel>
      <Select
        labelId="year-filter-label"
        id="year-filter"
        multiple
        value={yearFilters}
        onChange={handleChange}
        input={<OutlinedInput label="Year " />}
        renderValue={(selected) => selected.join(", ")}
      >
        {yearOptions.map((item) => (
          <MenuItem key={item.year} value={item.year}>
            <Checkbox checked={yearFilters.indexOf(item.year) > -1} />
            <ListItemText primary={`${item.year} (${item.count})`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
