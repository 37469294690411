import { Box } from "@mui/material";
import { AuthClientError, AuthClientEvent } from "@react-keycloak/core";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import React, { useCallback, useState } from "react";
import App from "./App";
import keycloak from "./keycloak";
import { TokenUpdater } from "./TokenUpdater";

const initOptions = {
  onload: "login-required",
};

export function AppWrapper() {
  const [tokenCreatedTime, setTokenCreatedTime] = useState<null | Date>(null);

  const eventLogger = useCallback(
    (event: AuthClientEvent, error: AuthClientError | undefined) => {
      console.log("KeycloakEvent =", event, error);
      if (["onAuthRefreshSuccess", "onAuthSuccess"].includes(event)) {
        setTokenCreatedTime(new Date());
      }
    },
    []
  );

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      onEvent={eventLogger}
    >
      <TokenUpdater refreshTime={tokenCreatedTime} />
      <Box height="100%" width="100%">
        <App />
      </Box>
    </ReactKeycloakProvider>
  );
}
