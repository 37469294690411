import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import ExitIcon from "@mui/icons-material/ExitToApp";
import { HelpBox } from "./HelpBox";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() =>
  createStyles({
    centerId: {
      marginLeft: 10,
      fontStyle: "italic",
      color: "#424242",
      textTransform: "capitalize",
    },
  })
);

type Props = {
  logout: () => void;
  centerId: string | null;
};

export function TitleBox({ logout, centerId }: Props) {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            FMP PostOPS API
            <sub className={classes.centerId}>
              {centerId?.toUpperCase() ?? ""}
            </sub>
          </Typography>
          <HelpBox centerId={centerId} />
          <Button
            sx={{ m: 1 }}
            variant="contained"
            endIcon={<ExitIcon />}
            onClick={logout}
          >
            <Box sx={{ display: { xs: "none", md: "block" } }}>LOGOUT</Box>
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}
