import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() =>
  createStyles({
    info: { paddingLeft: 50, paddingTop: 50 },
    tips: {
      paddingTop: 16,
      display: "flex",
      fontStyle: "italic",
      justifyContent: "center",
    },
    subTitle: {
      backgroundColor: "#222c38",
      "& Typography": { flexGrow: 1, justify: "whiteSpace" },
    },
  })
);

export const FatalBox = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ bgcolor: "#222c38" }}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            FATAL ERROR...
          </Typography>
        </Toolbar>
      </AppBar>
      <Box className={classes.info}>
        Mauvaise configuration de votre compte de connexion !<br></br> Contactez
        l'administrateur de l'API.
      </Box>
      <Box className={classes.tips}>
        (l'id du centre est manquant ou incorrect)
      </Box>
    </>
  );
};
