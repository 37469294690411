import axios from "axios";
import { API_BASE_URL } from "../Constant";
import { taskManager } from "./taskManager";

const API_URL = new URL("/upload", API_BASE_URL);

const resultStatus = {
  success: "success",
  failed: "failed",
} as const;

async function sendOneFile(
  file: File
): Promise<{ name: string; status: "success" | "failed" }> {
  const formData = new FormData();
  formData.append("file", file);
  return await axios
    .post(API_URL.toString(), formData)
    .then(() => {
      return {
        name: file.name,
        status: resultStatus.success,
      };
    })
    .catch((err) => {
      console.error(err);
      return {
        name: file.name,
        status: resultStatus.failed,
      };
    });
}

export const sendFile = taskManager.wrap(sendOneFile);
